// Http Headers Constants
export const TOKEN = 'sid';
export const REFRESH_TOKEN = 'sid-rtoken';
export const DEFAULT_ACCESS_DENIED_CODE = 'access_denied';
export const DEFAULT_ACCESS_DENIED_MESSAGE = `You don't have permission to perform the required action.`;
export const DEFAULT_ERROR_MESSAGE = 'Server rejected your request. Please try again!';

export const DEFAULT_INVALID_TOKEN_SERVER_RESPONSE = 'Token not provided';
export const DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE =
  'Token Signature could not be verified.';
export const CONFIRM_LEAVE_CHANGES_MSG: string =
  'Are you sure you want to leave the screen without saving the changes?';
export const CONFIRM_LEAVE_PREDICT_MSG: string =
  'Are you sure to leave the screen? Make sure to export your results if you need a copy of them.';

export const PLACEHOLDER_IMG: string = '/assets/images/img-placholder.svg';
export const PLACEHOLDER_IMG_BG: string = '/assets/images/placeholder-blog.jpg';

export const SUCCESS_STATUS: any = 200;
export const SUCCESS_STATUS_CODE: any = 201;
export const REQUEST_CONFLICT_STATUS: any = 409;
export const AUTH_FAILED_REQUEST_CONFLICT_STATUS: any = 401;

export const SEARCH = 'search';
export const CREATED_BY = 'createdBy';
export const UPDATED_BY = 'updatedAt';
export const DEFAULT_SORT_KEY = 'createdAt';
export const DATASETS_IMAGES_SORTING = 'dataset_images.id';
export const DEFAULT_SORT_ORDER = 'DESC';
export const NUMBER_RECORDS_PER_PAGE = 20;
export const DEFAULT_PAGE_INDEX = 1;
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];

export const DEFAULT_DEBOUNCE_TIME = 300;
export const DEFAULT_DEBOUNCE_TIME_1_SEC = 1000;

export const DEFAULT_DEBOUNCE_TIME_ACTIVITY = 10 * 60 * 1000; // 10 min

export const PERMISSION_READ: string = 'read';
export const ROLE_SUPER_ADMIN: string = 'super_admin';
export const ROLE_GLOBAL_ADMIN: string = 'global_admin';
export const ROLE_ADMIN: string = 'admin';
export const ROLES: any[] = [
  {
    title: 'Super Admin',
    value: ROLE_SUPER_ADMIN,
  },
  {
    title: 'Global Admin',
    value: ROLE_GLOBAL_ADMIN,
  },
  {
    title: 'Admin',
    value: ROLE_ADMIN,
  },
  {
    title: 'Content Manager',
    value: 'content_manager',
  },
  {
    title: 'Account Manager',
    value: 'account_manager',
  },
  {
    title: 'Data Scientist',
    value: 'data_scientist',
  },
  {
    title: 'Data Analyst',
    value: 'data_analyst',
  },
  {
    title: 'Customer',
    value: 'customer',
  },
];

export const SPECIFIC_ROLES: any[] = [
  'attendant',
  'driver',
  'inspector',
  'commercial_sales_agent',
  'sales_agent',
];

export const FEATHER: string = 'feather';
export const ICONSAX: string = 'iconsax';

export const STATUS = [
  {
    name: 'Active',
    value: 'active',
    frenchName: 'ACTIVE',
  },
  {
    name: 'Inactive',
    value: 'inactive',
    frenchName: 'INACTIVE',
  },
  {
    name: 'Draft',
    value: 'draft',
    frenchName: 'DRAFT',
  },
];



export const ADMIN_ROLES = [
  // {
  //   name: 'Designer',
  //   value: 'designer',
  //   frenchName: 'DESIGNER',
  // },
  {
    name: 'Admin',
    value: 'admin',
    frenchName: 'Admin',
  },
  {
    name: 'Content Creator',
    value: 'content_creator',
    frenchName: 'Content Creator',
  },
  {
    name: 'Operator',
    value: 'operator',
    frenchName: 'Operator',
  },
];

export const USER_STATUS = [
  {
    name: 'Active',
    value: 'active',
    frenchName: 'Active',
  },
  {
    name: 'Inactive',
    value: 'inactive',
    frenchName: 'Inactive',
  },
  {
    name: 'Unverified',
    value: 'unverified',
    frenchName: 'Unverified',
  },
];

export const PROFILE_STATUS = [
  {
    name: 'Incomplete',
    value: 'incomplete',
    frenchName: 'Incomplete',
  },
  {
    name: 'Pending Review',
    value: 'pending_review',
    frenchName: 'Pending Review',
  },
  {
    name: 'Accepted',
    value: 'accepted',
    frenchName: 'Accepted',
  },
  {
    name: 'Rejected',
    value: 'rejected',
    frenchName: 'Rejected',
  },
];

export const ITEM_STATUS = [
  {
    name: 'Active',
    value: 'active',
    frenchName: 'Active',
  },
  {
    name: 'Inactive',
    value: 'inactive',
    frenchName: 'Inactive',
  },
];
export const CATEGORY_STATUS = [
  {
    name: 'Active',
    value: 'active',
    frenchName: 'Active',
  },
  {
    name: 'Inactive',
    value: 'inactive',
    frenchName: 'Inactive',
  },
];

export const SUB_CATEGORY_STATUS = [
  {
    name: 'Active',
    value: 'true'
  },
  {
    name: 'Inactive',
    value: 'false'
  },
];

export const APP_STATUS: any = {
  active: 'Active',
  inactive: 'Inactive',
  unverified: 'Unverified',
  published: 'Published',
  unpublished: 'Un-Published',
  draft: 'Draft',
  pending: 'Pending',
  damaged: 'Damaged',
  inrepair: 'In Repair',
  obsolete: 'Obsolete',
  maintenance: 'Maintenance',
  out_of_service: 'Out Of Service',
  incomplete: 'Incomplete',
  pending_review: 'Pending Review',
  accepted: 'Accepted',
  rejected: 'Rejected',
};

export const STATUS_ARRAY: any = [
  {
    label: 'GENERAL.BUTTONS.ACTIVATE',
    value: 'active',
    icon: 'tick-circle',
  },
  {
    label: 'GENERAL.BUTTONS.DEACTIVATE',
    value: 'inactive',
    icon: 'close-circle',
  },
  // {
  //   label: 'GENERAL.BUTTONS.DRAFT',
  //   value: 'draft',
  //   icon: 'minus-cirlce',
  // },
];

// Inference & Dataset Image constant
export const DATASET_IMAGE_COUNT_KEY: string = 'sid-dataset-image-count';
export const INFERENCE_PROJECT_IMAGE_LIST_KEY: string = 'sid-inference-project-images';
export const DATASET_UPLOADED_IMAGE_COUNT_KEY: string = 'sid-dataset-uploaded-image-count';
export const DATASET_ID_BACKGROUND_IMAGE_KEY: string = 'sid-dataset-id-for-image-uploading';
export const INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY: string =
  'sid-inference-image-uploading';
export const BATCH_INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY: string =
  'sid-batch-inference-image-uploading';

// App Config
export const APP_CONFIG_KEY: string = 'sid-app-config';

// Fabric point constant
export const TOOLKIT_SHAPE_POINT_WIDTH: number = 10;
export const TOOLKIT_SHAPE_POINT_HEIGHT: number = 10;
export const nameRegex = /^(\D*)$/;
export const emailRegex = '^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\\.[a-zA-Z]{2,4}$';
export const digitsRegex = /^\d{6,8}$/;
export const alphanumericRegex = /^[a-zA-Z0-9]{6,8}$/;
export const alphanumericWithFrenchRegex = /^[\p{L}\d!@#$%^&*()_\[\]{};':"\\|,.<>\/?`~]{6,8}$/u;

export const passwordSpecialRegex = /^\d{6}$/;
export const OnlyStringNotOperators = /^[a-zA-ZÀ-ÖØ-öø-ÿ\sć]*$/;
export const passwordRegex: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-$@$!%*?&^#_+.~()'\"|\",<>{}\\[\];/=:])[A-Za-z\d-$@$!%*?&^#_+.~()'\"|\",<>{}\\[\];/=:]{8,16}$/;

export const passwordRegexOld: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-$@$!%*?&^#_+.~()'\"|\",<>{}\\\[\];/=:])[A-Za-z\d-$@$!%*?&^#_+.~()'\"|\",<>{}\\\[\];/=:].{8,}$/;
export const TOOLKIT_OPACITY_MASK: number = 0.4; // 0.8 old mask opacity
export const TOOLKIT_OPACITY_POLYGON: number = 0.4;
export const MSG_UPGRADE_BUNDLE: string =
  'Please upgrade your plan as you have reached the limit allowed to you in the current bundle.';
export const MSG_PRIVATE_PROJECTS_DATASETS_LIMIT_EXCEED: string =
  'Current amount of Private Datasets/Projects exceeds the allowed limit of the Number of Private Datasets/Projects';
export const GET_CURRENT_TIMEZONE: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const CURRENT_USER_DATA: string = 'sid-user';

export const supportedLanguages: string[] = ['en', 'fr'];


export const subCategoryTitleRegex = /^[A-Za-z\s]{1,60}$/;

export const subCategoryDescriptionRegex = /^[A-Za-z\s]{1,100}$/;

