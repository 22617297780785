export const PERMISSIONS_LIST = {
  VIEW_USERS: 'view_users',
  UPDATE_USERS: 'update_users',
  UPDATE_STORES: 'update_stores',
  VIEW_ROLES: 'view_roles',
  UPDATE_ROLES: 'update_roles',
  VIEW_CUSTOMERS: 'view_customers',
  UPDATE_CUSTOMERS: 'update_customers',
  VIEW_SYSTEM_LOG: 'view_system_log',
  VIEW_VEHICLES: 'view_vehicles',
  UPDATE_VEHICLES: 'update_vehicles',
  VIEW_STOPS: 'view_stops',
  UPDATE_STOPS: 'update_stops',
  VIEW_PATTERNS: 'view_patterns',
  UPDATE_PATTERNS: 'update_patterns',
  VIEW_SEGMENTS: 'view_segments',
  UPDATE_SEGMENTS: 'update_segments',
  VIEW_LINES: 'view_lines',
  UPDATE_LINES: 'update_lines',
  VIEW_TRIPS: 'view_trips',
  UPDATE_TRIPS: 'update_trips',
  VIEW_TIMETABLES: 'view_timetables',
  UPDATE_TIMETABLES: 'update_timetables',
  VIEW_ZONES: 'view_zones',
  UPDATE_ZONES: 'update_zones',
  VIEW_SUBZONES: 'view_subzones',
  UPDATE_SUBZONES: 'update_subzones',
  VIEW_SCHEDULE_SERVICE: 'view_schedule_service',
  UPDATE_SCHEDULE_SERVICE: 'update_schedule_service',
  VIEW_SCHEDULE_PLAN: 'view_schedule_plan',
  UPDATE_SCHEDULE_PLAN: 'update_schedule_plan',
  VIEW_INTERCITY_FARES: 'view_intercity_fares',
  UPDATE_INTERCITY_FARES: 'update_intercity_fares',
  VIEW_URBAN_FARES: 'view_urban_fares',
  UPDATE_URBAN_FARES: 'update_urban_fares',
  VIEW_PASSES: 'view_passes',
  UPDATE_PASSES: 'update_passes',
  VIEW_CARDS: 'view_cards',
  UPDATE_CARDS: 'update_cards',
  VIEW_DEVICE: 'view_device',
  UPDATE_DEVICE: 'update_device',
  VIEW_FRAUD_FINE: 'view_fraud_fine',
  UPDATE_FRAUD_FINE: 'update_fraud_fine',
  VIEW_SETTLEMENT: 'view_settlement',
  UPDATE_SETTLEMENT: 'update_settlement',
  VIEW_TRANSACTION: 'view_transaction',
  UPDATE_TRANSACTION: 'update_transaction',
  VIEW_HOLIDAYS: 'view_holiday',
  UPDATE_HOLIDAYS: 'update_holiday',
  VIEW_EMPLOYEE_ROSTER: 'view_employee_roster',
  UPDATE_EMPLOYEE_ROSTER: 'update_employee_roster',
  VIEW_EMPLOYEE_ENGAGEMENT: 'view_employee_engagement',
  UPDATE_EMPLOYEE_ENGAGEMENT: 'update_employee_engagement',
  VIEW_VEHICLE_DISPATCHING: 'view_vehicle_dispatching',
  UPDATE_VEHICLE_DISPATCHING: 'update_vehicle_dispatching',
  VIEW_COLLECTION_REVENUE: 'view_collection_revenue',
  UPDATE_COLLECTION_REVENUE: 'update_collection_revenue',
  VIEW_SHIFT_APP: 'view_shift_app',
  UPDATE_SHIFT_APP: 'update_shift_app',
  VIEW_FRAUD_APP: 'view_fraud_type',
  UPDATE_FRAUD_TYPE: 'update_fraud_type',
  VIEW_BOOKING: 'view_booking',
  UPDATE_BOOKING: 'update_booking',
  VIEW_BOOKING_TICKET: 'view_booking_ticket',
  UPDATE_BOOKING_TICKET: 'update_booking_ticket',
  VIEW_NOTIFICATION: 'view_notification',
  UPDATE_NOTIFICATION: 'update_notification',
  VIEW_PASSENGER_NOTIFICATION: 'view_passenger_notification',
  UPDATE_PASSENGER_NOTIFICATION: 'update_passenger_notification',
  VIEW_URBAN_NETWORK_AND_PLANNING: 'view_urban-network-and-planning',
  UPDATE_URBAN_NETWORK_AND_PLANNING: 'urban-network-and-planning',
  VIEW_RIDERSHIP: 'view_ridership_report',
  UPDATE_RIDERSHIP: 'update_ridership_report',
  VIEW_CUSTOMER_REPORT: 'view_customer_report',
  UPDATE_CUSTOMER_REPORT: 'update_customer_report',
  VIEW_ATTENDANT_REPORT: 'view_attendant_report',
  UPDATE_ATTENDANT_REPORT: 'update_attendant_report',
  VIEW_SALES_AGENT_REPORT: 'view_sales_agent_report',
  UPDATE_SALES_AGENT_REPORT: 'update_sales_agent_report',
  VIEW_FINANCE: 'view_finance_report',
  UPDATE_FINANCE: 'update_finance_report',
};
