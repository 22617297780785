('use strict');
import { AppConfigurations } from '../config/app.config';
const apiConfig = new AppConfigurations();
export const APP_TITLE: string = apiConfig.config.title;
export const APP_ENVIRONMENT: string = apiConfig.config.environment;
export const SERVER_URL: string = apiConfig.config.apiUrl;
export const S3_BUCKET_URL: string = apiConfig.config.s3BucketUrl;

export const LOGOUT: string = `/auth/logout`;
export const GET_USER_DETAILS: string = `users/me`;
export const USER_MANAGEMENT_STATUS_UPDATE: string = `users/{id}/status`;
export const GET_DEFAULTS_BASE_URL: string = `system/home`;

export const LINES_BASE_URL: string = `network/lines`;

export const AUTH_REFRESH_TOKEN_BASE_URL: string = `/auth/refresh-token`;
export const USER_REFRESH_TOKEN_BASE_URL: string = `users/refresh-token`;

export const ADD_SERVICE_SHIFTS_BULK_ASSIGN: string = `schedule/service-shifts/bulk-assign-to-row/{rowId}`;
export const SERVICE_SHIFTS_UNASSIGNED_BASE_URL: string = `schedule/service-shifts/unassigned`;
export const UPDATE_SERVICE_SHIFTS_BULK_UN_ASSIGN: string = `schedule/service-shifts/bulk-un-assign/{planId}`;
export const HOLIDAYS_BASE_URL: string = `schedule/holidays`;

// Extra APIs List

// Schedule Rows Management
export const SERVICE_ROWS_BASE_URL: string = `schedule/rows`;
export const UPDATE_SERVICE_ROWS_ASSIGN_URBAN_TEAM: string = `${SERVICE_ROWS_BASE_URL}/(id}/assign-urban-team`;
export const UPDATE_SERVICE_ROWS_ASSIGN_INTERCITY_TEAM: string = `${SERVICE_ROWS_BASE_URL}/(id}/assign-intercity-team`;
export const UPDATE_SERVICE_ROWS_UN_ASSIGN_URBAN_TEAM: string = `${SERVICE_ROWS_BASE_URL}/{id}/un-assign-urban-team`;
export const UPDATE_SERVICE_ROWS_UN_ASSIGN_INTERCITY_TEAM: string = `${SERVICE_ROWS_BASE_URL}/(id}/un-assign-intercity-team`;

// Customers Management

export const CUSTOMERS_BASE_URL: string = `customers`;
export const CUSTOMER_BY_ID_BASE_URL: string = `${CUSTOMERS_BASE_URL}/{id}`;
export const UPDATE_CUSTOMER_STATUS_BASE_URL: string = `${CUSTOMERS_BASE_URL}/{id}/status`;

// System Documents
export const DOCUMENTS_UPLOAD_BASE_URL: string = `system/documents`;
export const DOCUMENT_DOWNLOAD: string = `${DOCUMENTS_UPLOAD_BASE_URL}/{id}/download`;
export const DOCUMENT_DELETE: string = `${DOCUMENTS_UPLOAD_BASE_URL}/{id}/delete`;
export const DOCUMENT_UPLOAD: string = `${DOCUMENTS_UPLOAD_BASE_URL}/upload/{code}`;

export const DOCUMENT_BULK_UPLOAD: string = `${DOCUMENTS_UPLOAD_BASE_URL}/bulk-upload/{code}`;
/////////////////////////////

// // Frauds Fines
// // export const FRAUDS_BASE_URL: string = `${SERVER_URL}ticket/frauds-fines`;
// // export const FRAUD_BY_ID_BASE_URL: string = `${FRAUDS_BASE_URL}/{id}`;
// // export const UPDATE_FINE_AMOUNT: string = `${FRAUD_BY_ID_BASE_URL}/fine-amount-update`;

// // Fraud Types
// export const FRAUDS_TYPES_BASE_URL: string = `${SERVER_URL}ticket/fraud-types`;
// export const FRAUD_TYPE_BY_ID_BASE_URL: string = `${FRAUDS_TYPES_BASE_URL}/{id}`;
// export const FRAUD_TYPE_STATUS_UPDATE: string = `${FRAUDS_TYPES_BASE_URL}/{id}/status`;

// // Schedule Holidays Management
// export const HOLIDAYS_BASE_URL: string = `${SERVER_URL}schedule/holidays`;
// export const HOLIDAYS_BY_ID_BASE_URL: string = `${HOLIDAYS_BASE_URL}/{id}`;

// // Vehicles Dispatching
// export const NETWORK_TIMETABLE_BASE_URL: string = `${SERVER_URL}network/timetable-blocks`;
// export const NETWORK_TIMETABLE_BY_ID_BASE_URL: string = `${NETWORK_TIMETABLE_BASE_URL}/{id}`;
// export const NETWORK_TIMETABLE_ASSIGN_VEHICLE: string = `${NETWORK_TIMETABLE_BASE_URL}/{id}/assign-vehicle`;
// export const NETWORK_TIMETABLE_ASSIGN_REPLACEMENT_VEHICLE: string = `${NETWORK_TIMETABLE_BASE_URL}/{id}/assign-replacement-vehicle`;

// // Nfc Cards
// export const NFC_CARDS_BASE_URL: string = `${SERVER_URL}cards`;
// export const NFC_CARD_BY_ID_BASE_URL: string = `${NFC_CARDS_BASE_URL}/{id}`;
// export const NFC_CARD_DETAILS_BY_ID_BASE_URL: string = `${NFC_CARDS_BASE_URL}/{id}/details`;
// export const NFC_CARDS_BULK_DELETE: string = `${NFC_CARDS_BASE_URL}/bulk/delete`;
// export const NFC_CARD_EXPORT: string = `${NFC_CARDS_BASE_URL}/export`;
// export const NFC_CARD_DOWNLOAD_TEMPLATE: string = `${NFC_CARDS_BASE_URL}/import/download-template`;
// export const NFC_CARD_IMPORT: string = `${NFC_CARDS_BASE_URL}/import/from-excel`;

// // Devices
// export const DEVICES_BASE_URL: string = `${SERVER_URL}ticket/devices`;
// export const DEVICE_BY_ID_BASE_URL: string = `${DEVICES_BASE_URL}/{id}`;
// export const UPDATE_DEVICE_STATUS_BASE_URL: string = `${DEVICE_BY_ID_BASE_URL}/status`;

// // Collection Revenue
// export const COLLECTION_REVENUE_BASE_URL: string = `${SERVER_URL}revenue-collection`;

// // Notifications
// export const NOTFICATIONS_BASE_URL: string = `${SERVER_URL}system/notifications`;
// export const NOTFICATIONS_BY_ID: string = `${NOTFICATIONS_BASE_URL}/{id}`;
// export const NOTFICATIONS_READ: string = `${NOTFICATIONS_BY_ID}/read`;
// export const NOTFICATIONS_BULK_READ: string = `${NOTFICATIONS_BASE_URL}/bulk/read`;
// export const NOTFICATIONS_BULK_DELETE: string = `${NOTFICATIONS_BASE_URL}/bulk/read`;

// // Reports
// export const REPORTS_BASE_URL: string = `${SERVER_URL}reports`;
// export const RIDERSHIP_REPORT_BASE_URL: string = `${REPORTS_BASE_URL}/ridership`;
// export const RIDERSHIP_REPORT_EXPORT: string = `${RIDERSHIP_REPORT_BASE_URL}/export`;
// export const CUSTOMER_REPORT_BASE_URL: string = `${REPORTS_BASE_URL}/customer`;
// export const CUSTOMER_REPORT_EXPORT: string = `${CUSTOMER_REPORT_BASE_URL}/export`;
// export const ATTENDANT_REPORT_BASE_URL: string = `${REPORTS_BASE_URL}/attendant`;
// export const ATTENDANT_REPORT_EXPORT: string = `${ATTENDANT_REPORT_BASE_URL}/export`;
// export const SALES_AGENT_REPORT_BASE_URL: string = `${REPORTS_BASE_URL}/sales-agent`;
// export const SALES_AGENT_REPORT_EXPORT: string = `${SALES_AGENT_REPORT_BASE_URL}/export`;
// export const FINANCE_REPORT_BASE_URL: string = `${REPORTS_BASE_URL}/finance`;
// export const FINANCE_REPORT_EXPORT: string = `${FINANCE_REPORT_BASE_URL}/export`;
